.statistic-card-active-icon {
  align-self: center;
  text-align: center;
  color: green;
  font-size: 35px;
}

.statistic-card-onhold-icon {
  align-self: center;
  text-align: center;
  color: red;
  font-size: 35px;
}

.statistic-title {
  text-align: right;
}
