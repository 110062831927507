body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5 !important;
}

#root {
  height: 100%;
}

@media (max-width: 763px) {
  .content {
    min-height: max-content !important;
    padding: 10px 20px;
  }
}

@media (min-width: 763px) {
  .content {
    min-height: max-content !important;
    padding: 12px 20px;
  }
}

.full-height {
  height: 100%;
}

.card-with-shadow {
  border-radius: 5px !important;
  box-shadow: 10px 11px 35px -12px rgba(0, 0, 0, 0.75);
}

.footer {
  text-align: center;
  margin-top: 10px;
  padding: 10px 20px !important;
  bottom: 0;
  background-color: #fdfdff !important;
  color: #1890ff !important;
}

.space-top {
  margin-top: 20px !important;
}

.space-top-10 {
  margin-top: 10px !important;
}

.space-top-5 {
  margin-top: 5px !important;
}

.site-layout-background {
  background: #fff !important;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.text-center {
  text-align: center;
}

.ant-typography ul {
  margin: 0 !important;
  padding: 0 !important;
}

@media (max-width: 763px) {
  .ant-timeline-item-content {
    width: calc(50% - 15px) !important;
  }
  .ant-card-body {
    padding: 10px !important;
  }
}

@media (min-width: 763px) {
  .ant-timeline-item-content {
    width: calc(80% - 14px) !important;
  }
}
